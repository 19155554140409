import React from 'react';
import CreatePostButton from './create-post-button';
import PageTitle from './page-title';
import PageTabs from './page-tabs';
import styles from './my-posts-frame.scss';

const MyPostsFrame = ({ children }) => (
  <div className={styles.root}>
    <div className={styles.header}>
      <PageTitle />
      <CreatePostButton />
    </div>
    <PageTabs />
    {children}
  </div>
);

export default MyPostsFrame;
